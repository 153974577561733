import { FC, PropsWithChildren } from 'react';
import { gridColsLookup } from '@/context/UserSettingsContext';
import { GalleryView } from '@/types';
import { cn } from '@/lib/utils';

export const RenderGrid: FC<
  PropsWithChildren & {
    classname?: string;
    isSearchPage: boolean;
    isMobile: boolean;
    galleryView: string;
    assetSize: Array<number>;
    isPublicRoute?: boolean;
  }
> = ({ children, classname, isSearchPage, isMobile, galleryView, assetSize, isPublicRoute = false }) => (
  <div
    className={cn(
      'relative grid gap-5 p-4',
      isSearchPage && 'md:pt-32',
      isPublicRoute && 'pb-32',
      isMobile
        ? galleryView === GalleryView.LIST
          ? ''
          : 'grid-cols-2'
        : galleryView === GalleryView.LIST
        ? 'grid-cols-1 pb-32'
        : gridColsLookup[assetSize[0]] || 'grid-cols-7',
      classname,
    )}
  >
    {children}
  </div>
);
